.summary-container {
  background-color: #f58634;
  .summary-details {
    display: flex;
    justify-content: center;
    padding: 25px;
    text-align: center;

    .card {
      margin: 10px;
      background-color: white;
      border-width: 0px;
      color: #f58634;
      border-radius: 10px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    img {
      max-width: 18rem;
      max-height: 18rem;
    }
  }
}

@media only screen and (max-width: 800px) {
  .summary-container {
    .summary-details {
      flex-flow: column;
    }
  }
}

@media only screen and (max-width: 500px) {
  .summary-container {
    .summary-details {
      flex-flow: column;
    }
  }
}
