// Header
.header {
  height: 100%;
  display: block;
  position: sticky;
  box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.4);
  background-color: #f4fdf9;

  .navbar-brand {
    font-size: 50px !important;
    font-weight: 500;
    color: #a6474b;
  }

  .quick-info {
    background-color: #f58634;
    color: white;
    font-size: medium;
    font-weight: bold;
    padding: 5px;
  }

  .nav-item {
    a {
      text-decoration: none;
      font-size: 17px;
      font-weight: 600;
      padding: 15px;
      color: #f58634;
    }

    .link-active {
      color: #a6474b;
    }
  }
}

// Contact US
.contact-us-container {
  h1 {
    color: #a6474b;
    text-align: center;
  }
  .info-card {
    background-color: #f58634;
    border-radius: 15px;
    margin: 25px;
    color: white;

    form {
      padding: 15px;
    }

    button {
      background-color: #a6474b;
      color: white;
    }
  }
}

// Footer

.footer {
  background-color: #f58634;
  height: 100%;
  color: white;
  padding: 20px;

  .social-media-links {
    a {
      padding: 5px;
    }
  }

  .footer-logo {
    img {
      border-radius: 15px;
    }
  }

  ul {
    li {
      a {
        text-decoration: none;
        font-size: 15px;
        font-weight: 400;
        // padding: 15px;
        color: white;
      }
    }
  }
}

.divider {
  width: 85%;
  margin: 0 auto;
  border-top: 1px solid white;
  padding: 15px 0 0;
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 500px) {
}
