.documents-container {
  padding: 15px;
  h1 {
    color: #a6474b;
    text-align: center;
  }
  h5 {
    text-align: center;
    a {
      color: #a6474b;
      // text-decoration: none;
    }
  }
  .documents-table {
    background-color: #f58634;
    color: white;
    border-radius: 15px;
    padding: 15px;
    margin-top: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    table {
      color: white;

      button {
        border-radius: 5px;
        border-width: 0px;
        background-color: white;

        a {
          color: #a6474b;
          text-decoration: none;
          font-weight: 500;
          font-size: 15px;
        }
      }
    }
  }
}
